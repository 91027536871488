  //  Foundation for Sites Settings
  //  -----------------------------

@font-face {
    font-family: 'majesti_bannerbold';
    src: url('/assets/font/majesti-banner-bold-webfont.woff2') format('woff2'),
         url('/assets/font/majesti-banner-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'majesti_bannerbold_italic';
    src: url('/assets/font/majesti-banner-bolditalic-webfont.woff2') format('woff2'),
         url('/assets/font/majesti-banner-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'majesti_bannerheavy';
    src: url('/assets/font/majesti-banner-heavy-webfont.woff2') format('woff2'),
         url('/assets/font/majesti-banner-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'majesti_bannerheavy_italic';
    src: url('/assets/font/majesti-banner-heavyitalic-webfont.woff2') format('woff2'),
         url('/assets/font/majesti-banner-heavyitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'glacial_indifferencebold';
    src: url('/assets/font/glacialindifference-bold-webfont.woff2') format('woff2'),
         url('/assets/font/glacialindifference-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'glacial_indifferenceitalic';
    src: url('/assets/font/glacialindifference-italic-webfont.woff2') format('woff2'),
         url('/assets/font/glacialindifference-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'glacial_indifferenceregular';
    src: url('/assets/font/glacialindifference-regular-webfont.woff2') format('woff2'),
         url('/assets/font/glacialindifference-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$foundation-palette: (
  primary: #ff0000,
  secondary: #1750a5,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

$body-font-family: "glacial_indifferenceregular", sans-serif;
$header-font-family: "majesti_bannerbold", serif;
$header-font-family-italic: "majesti_bannerbold_italic", serif;
$header-font-family-bold: "majesti_bannerheavy", serif;
$header-font-family-bold-italic: "majesti_bannerheavy_italic", serif;

$grid-container-padding: 0;

$header-styles: (
  small: (
    'h1': ('font-size': 24),
    'h2': ('font-size': 20),
    'h3': ('font-size': 18),
    'h4': ('font-size': 16),
    'h5': ('font-size': 15),
    'h6': ('font-size': 14),
  ),
  medium: (
    'h1': ('font-size': 48),
    'h2': ('font-size': 36),
    'h3': ('font-size': 24),
    'h4': ('font-size': 20),
    'h5': ('font-size': 16),
    'h6': ('font-size': 14),
  ),
) !default;
// --------------------------------------------------
// Theme
// --------------------------------------------------

body {
    font-size: 18px;
}

/* Avoid text right against the margin on small screens */
@media only screen and (max-width: 1225px) {
    body {
        margin: 0 5px;
    }
}

em {
    font-family: 'glacial_indifferenceitalic';
}

strong {
    font-family: 'glacial_indifferencebold';
}

p {
    margin-bottom: $paragraph-margin-bottom;
}

h1, h2 {
    background-color: #000;
    color: #fff;
    display: inline;

    a {
        color: #fff;
    }
}

h3, h4, h5, h6 {
    font-family: $body-font-family;
    font-weight: bold;
}

blockquote {
    color: #000;
    padding: 1rem 2rem;
    border-left: 0;
}

blockquote p {
    color: #000;
}

figure {
    text-align: center;
    margin: (4 * $card-margin-bottom) 0;
}


article.essay figure img {
    margin: 0 0 $paragraph-margin-bottom;
    max-height: 90vh;
    width: auto;
}

figure iframe {
    background-color: transparent;
    border: 0px none transparent;
    padding: 0px;
    overflow: hidden;
    width: 100%;
}

figcaption {
    border-top: 5px solid #000;
    padding: 1rem;
    border-bottom: 1px solid #000;
    text-align: left;
}

header,
footer {
    background-color: transparent;
}

footer img {
    max-height: 2 * $base-line-height;
    margin-bottom: $paragraph-margin-bottom;
    margin-right: $paragraph-margin-bottom;
    width: auto;
}

footer .cell.large-6 {
    padding-right: $paragraph-margin-bottom;
}

body > header {
    border-top: 10px solid black;
}

body > header h1 {
    font-size: 40px;
    line-height: 40px;
    margin-top: 3 * $subheader-margin-top;
    background-color: transparent;
    display: block;
}

body > header h1 a {
    color: #000;
}

.menu li {
    border-right: 1px solid black;
}

.menu a {
    color: $secondary-color;
}

div.essay h3 small {
    display: block;
    margin-top: $subheader-margin-top;
}

div.essay:first-of-type {
    margin-top: 1rem;
}

div.essay .author {
    display: block;
}

div.essay button {
    margin: $global-margin 0;
}

div.essay .abstract {
    display: none;
    margin-bottom: $paragraph-margin-bottom;
}

.abstract + blockquote {
    font-size: 2 * $base-font-size;
    border-left: 0;
    padding: 0 3rem;
}

.volume {
    position: relative;
    margin-bottom: $card-margin-bottom;
}

.volume .volume-number {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
    width: 30px;
    font-family: $header-font-family-bold;
    line-height: 35px;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 50px solid $primary-color;
        border-right: 50px solid transparent;
        z-index: -1;
    }
}


.volume a img:last-child,
.volume a:hover img:first-child,
.volume a:active img:first-child {
    display: none;
}

.volume a:hover img:last-child,
.volume a:active img:last-child {
    display: block;
}

article.essay img {
    margin: (2 * $card-margin-bottom) 0;
}

article.essay img.orcid {
    margin: 0 .25rem .5rem 0;
    display: inline-block;
}

.essay-header {
    text-align: center;
    margin: 3rem 0;
}

.essay-header h2 {
    font-size: 4em;
}

.essay-header h2 small {
    color: #000;
    background-color: #fff;
    line-height: 100%;
    display: block;
    font-family: $header-font-family-bold;
    margin-top: 1rem;
}

.essay-header .author,
.essay-header .meta {
    display: block;
    margin: 1rem 0;
}

.essay-header .author {
    font-family: $header-font-family-bold;
    font-weight: bold;
    font-size: 20px;
}

article.essay .abstract {
    margin: 2rem 0;
}

.callout {
    border-top: 10px solid #000;
}

.volume-list.grid-x .volume {
    margin: $global-margin $global-margin $global-margin 0;
}

#volume-archive .volume {
    max-width: 350px;
}

.thumbnail img {
    max-width: 350px;
}

/* Wrap URLs in bibliography entries on small screens */
p a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
